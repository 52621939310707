import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import { getVariant, EXP_MODE, isWindow } from '../utils/commonUtil'
import SideBar from './SideBar'
import Layout from './Layout'
import {
  HomePage,
  ArticleFeed,
  BlogWrapper,
  BlogHeader,
  BlogTitle,
  Meta,
  MetaAuthor,
  // MiniPost,
  StyledImage,
  BlogBody,
  Banner,
  HomeTitle,
  LinkButton,
} from '../styles'

const Articles = ({ posts }) => {
  // unused method
  // const isCountries = () => {
  //   if (typeof window !== `undefined`) {
  //     return /countries/.test(window.decodeURI(window.document.URL))
  //   }
  // }

  return (
    <Layout removeFooter={true} adsense={false}>
      <HomePage>
        <HomeTitle>
          <h2>Path.Money</h2>
        </HomeTitle>
        {posts.length ? <SideBar posts={posts.slice(0, 4)} /> : null}
        <ArticleFeed>
          {posts.map(({ node: post }, index) => {
            let {
              frontmatter: { title, date, author, authorPicture, banner, snippet , alphaTest, betaTest},
              fields: { slug },
            } = post

            switch (getVariant(alphaTest, betaTest)) {
              case EXP_MODE.ALPHA:
                title = alphaTest.title || title
                banner = alphaTest.banner || banner
                snippet = alphaTest.snippet || snippet
                break
              case EXP_MODE.BETA:
                title = betaTest.title || title
                banner = betaTest.banner || banner
                snippet = betaTest.snippet || snippet
                break
              default:
                // during build set content to null if alpha beta testing is on
                if (
                  !isWindow &&
                  alphaTest &&
                  betaTest &&
                  (alphaTest.live || betaTest.live)
                ) {
                  title = null
                  banner = null
                }
            }

            return (
              <BlogWrapper key={`article-${post.id || index}`}>
                <BlogHeader>
                  <BlogTitle>
                    <Link to={slug}>
                      <h2>{title}</h2>
                    </Link>
                  </BlogTitle>
                  <Meta>
                    <time>{date}</time>
                    <div>
                      <MetaAuthor href="#">
                        {author && authorPicture && authorPicture.childImageSharp ? (
                          <Img fixed={authorPicture.childImageSharp.fixed} />
                        ) : null}
                        <span>{author}</span>
                      </MetaAuthor>
                    </div>
                  </Meta>
                </BlogHeader>
                <BlogBody>
                  <Banner>
                    {banner ? (
                      <Link to={slug}>
                        {banner && (
                          <StyledImage fluid={banner.childImageSharp.fluid} />
                        )}
                      </Link>
                    ) : null}
                  </Banner>
                  <p>{snippet}</p>
                  <LinkButton to={slug}>Continue Reading</LinkButton>
                </BlogBody>
              </BlogWrapper>
            )
          })}
        </ArticleFeed>
      </HomePage>
    </Layout>
  )
}

export default Articles
