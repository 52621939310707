import { Link } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'

import { AboutText, AboutDataContainer } from '../styles'
import { aboutHeader, aboutText } from '../utils/constants'
import {EXP_MODE, getVariant, isWindow} from '../utils/commonUtil'
import Footer from '../components/Footer'

import {
  MiniPost,
  StyledArticlesSection,
  StyledImage,
  StyledSideBar,
  StyledAboutSection,
} from '../styles'

const SideBar = ({ posts }) => {
  return (
    <StyledSideBar>
      {posts && posts.length > 1 ? (
        <StyledArticlesSection>
          {posts.map(({ node: post }) => {
            let {
              frontmatter: { title, date, authorPicture, banner, alphaTest, betaTest },
              fields: { slug },
            } = post
            switch (getVariant(alphaTest, betaTest)) {
              case EXP_MODE.ALPHA:
                title = alphaTest.title || title
                banner = alphaTest.banner || banner
                break
              case EXP_MODE.BETA:
                title = betaTest.title || title
                banner = betaTest.banner || banner
                break
              default:
                // during build set content to null if alpha beta testing is on
                if (
                  !isWindow &&
                  alphaTest &&
                  betaTest &&
                  (alphaTest.live || betaTest.live)
                ) {
                  title = null
                  banner = null
                }
            }

            return (
              <MiniPost data-testid="sidebar-mini-post" key={slug}>
                <Link to={slug}>
                  {banner && (
                    <StyledImage fluid={banner.childImageSharp.fluid} />
                  )}
                </Link>
                <header>
                  <div>
                    <h3>
                      <Link to={slug}>{title}</Link>
                    </h3>
                    <time>{date}</time>
                  </div>
                  {authorPicture && authorPicture.childImageSharp && (
                    <div>
                      <Link to={slug}>
                        <Img fixed={authorPicture.childImageSharp.fixed} />
                      </Link>
                    </div>
                  )}
                </header>
              </MiniPost>
            )
          })}
        </StyledArticlesSection>
      ) : null}
      <StyledAboutSection>
        <AboutDataContainer css={AboutText}>
          <h2>About</h2>
          <p>{aboutHeader}</p>
          <p>{aboutText}</p>
        </AboutDataContainer>
        <Footer />
      </StyledAboutSection>
    </StyledSideBar>
  )
}

export default SideBar
